import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import "./navBar.scss";
import { Link } from "react-router-dom";
import { LanguageSelector } from "./components/LanguageSelector";

export const NavBar = ({ active, partial }) => {
  const [postcardsActive, setPostcardsActive] = React.useState(active === "postales");
  const [mapActive, setMapActive] = React.useState(active === "mapa");

  return (
    <Navbar
      expand="sm"
      id="navbar"
      className="navbar"
      style={partial === undefined ? { width: "100vw", margin: 0 } : {}}
    >
      <Navbar.Brand className="navbar__brand" href="/">
        <img src="ui\logo.png" alt="" />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="navCollapse"
        className="navbar__collapseButton"
      />
      <Navbar.Collapse className="justify-content-end" id="navCollapse">
        <Nav>
          <Link
            className="navbar__tabLink"
            to="/postales"
            onMouseEnter={() => setPostcardsActive(true)}
            onMouseLeave={() => setPostcardsActive(active === "postales")}
          >
            {postcardsActive ? (
              <img src="ui\iconos-04.png" alt="" />
            ) : (
              <img src="ui\iconos-03.png" alt="" />
            )}
          </Link>
          <Link
            className="navbar__tabLink"
            to="/mapamundi"
            onMouseEnter={() => setMapActive(true)}
            onMouseLeave={() => setMapActive(active === "mapa")}
          >
            {mapActive ? (
              <img src="ui\iconos-02.png" alt="" />
            ) : (
              <img src="ui\iconos-01.png" alt="" />
            )}
          </Link>
          <LanguageSelector/>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
