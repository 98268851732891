import "./artworkContainer.scss";
import { Element } from "react-scroll";

export const ArtworkContainer = ({ artwork, i }) => {
  return (
    <article className="artwork" id={`artwork_${i}`}>
      <div className="artwork__img-parent">
        <img
          id={`img_${i}`}
          src={artwork.image}
          alt={artwork.title}
          className="artwork__img-parent__img"
        />
      </div>
      <div className="artwork__description">
        <div className="artwork__description__text-original">
          {artwork["text-original"].split("<br>").map((paragraph, j) => {
            return (
              <p
                key={`${artwork}_${i}_${j}`}
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
            );
          })}
        </div>
        <p className="artwork__description__author">
          {artwork.author}
          <br />
          {`(${artwork["country-es"]})`}
        </p>
        <p className="artwork__description__language">{`Orixinal en ${artwork["language-gal"]} / Original en ${artwork["language-es"]} / Original in ${artwork["language-en"]}`}</p>
        <p className="artwork__description__text-translated">
          <b>ES:</b> {artwork["text-es"]}
        </p>
        <p className="artwork__description__text-translated">
          <b>EN:</b> {artwork["text-en"]}
        </p>
      </div>
    </article>
  );
};
