import "./app.scss";
import { ArtworkGallery } from "./components/ArtworkGallery/ArtworkGallery";
import { NavBar } from "./components/navBar/NavBar";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Welcome } from "./components/welcome/Welcome";
import { WorldMap } from "./components/worldMap/WorldMap";
import Loading from "./components/loading/Loading";
import { useEffect } from 'react';


const ROUTES = [
  {
    path: "/",
    component: <Welcome />,
  },
  {
    path: "/postales",
    component: <ArtworkGallery />,
  },
  {
    path: "/mapamundi",
    component: <WorldMap />,
  },
  {
    path: "/load",
    component: <Loading />,
  }
];

function App() {
  useEffect(() => {
    document.title = "Amalgama";
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        {ROUTES.map((route, i) => (
          <Route path={route.path} key={i} element={route.component} />
        ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
