import React, { useState } from "react";
import "./languageSelector.scss";
import { useNavigate } from "react-router-dom";
export const LanguageSelector = () => {
    const [language, setLanguage] = useState(localStorage.getItem('selectedLanguage') || 'es');
    const navigate = useNavigate();

    function handleRefresh() {
        navigate("/");
        window.location.reload();
    }
    // Save the selected language to localStorage whenever it changes
    const handleLanguageChange = (event) => {
        const selectedLanguage = event.target.value;
        localStorage.setItem('selectedLanguage', selectedLanguage);
        setLanguage(selectedLanguage);
        handleRefresh();
    };

    return (
        <div className="language-selector">
            <select id="language-select" value={language} onChange={handleLanguageChange}>
                <option value="en">EN</option>
                <option value="es">ES</option>
            </select>
        </div>
    );
}


