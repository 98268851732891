import React from "react";
import "./welcome.scss";
import { NavBar } from "../navBar/NavBar";
import Loading from "../loading/Loading";

export const Welcome = () => {

  const [welcomeData, setWelcomeData] = React.useState(null);
  const [loaded, setLoaded] = React.useState(null);

  React.useEffect(() => {
    fetch("textos.json")
      .then((response) => response.json())
      .then((data) => {
        setWelcomeData(data.welcome);
        if (sessionStorage.getItem('loaded') !== 'true') {
          sessionStorage.setItem('loaded', 'false');
          setLoaded(false);
          setTimeout(() => {
            sessionStorage.setItem('loaded', 'true');
            setLoaded(true);
          }, 3500);
        } else {
          setLoaded(true);
        }
      });
  }, []);





  if (welcomeData) {
    const selectedLanguage = localStorage.getItem('selectedLanguage') || 'es';

    // Definir las cadenas de texto que se van a mostrar dependiendo del idioma
    const welcome = selectedLanguage === 'es' ? welcomeData.es : welcomeData.en;
    return (
      <>
        <NavBar />
        <Loading loaded={loaded} />
        <div className="welcome">
          <div className="welcome__text">
            <h1>{welcome.welcome}</h1>
            <p>{welcome.description1}</p>
            <p>{welcome.description2}</p>
            <h2>{welcome.howItWorks}</h2>
            <p>{welcome.step1}</p>
            <p>{welcome.step2}</p>
            <p>{welcome.gallery}
              <a target="_blank" href="https://www.instagram.com/amalgama.postal/">{welcome.link}</a>
              {welcome.afterlink}
            </p>
          </div>
          <img src="img/poster.png" alt="" />
        </div>
        <footer className="welcome-footer">
          <div className="welcome-footer__mail"><img src="ui/mail.png" alt="" />amalgama.postal@gmail.com</div>
          <div className="welcome-footer__insta"><img src="ui/insta.png" alt="" />@amalgama.postal</div>
        </footer>
      </>
    );
  }
};
