import React, { useState, useEffect, useRef } from "react";
import { ArtworkContainer } from "./ArtworkContainer/ArtworkContainer";
import "./artworkGallery.scss";
import Carousel from "./Carousel/Carousel";
import { NavBar } from "../navBar/NavBar";

export const ArtworkGallery = () => {
  const [artworkData, setArtworkData] = useState(null);

  useEffect(() => {
    fetch("postales.json")
      .then((response) => response.json())
      .then((data) => {
        setArtworkData(data.artworks);
      });
  }, []);

  if (!artworkData) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="main">
        <Carousel
          images={artworkData
            .filter((artwork) => artwork.image)
            .map((artwork) => "img/" + artwork.image)}
        ></Carousel>
        <section className="artwork-gallery">
          <NavBar active={"postales"} partial={true} />
          <div className="artworks-parent">
            {artworkData
            .filter((artwork) => artwork.image)
            .map((artwork, i) => (
              <ArtworkContainer artwork={{...artwork, image:"img/" + artwork.image}} i={i} />
            ))}
          </div>
        </section>
        <footer style={{ height: "265px" }}></footer>
      </div>
    );
  }
};
