import React from 'react';
import './worldMap.scss';
import { NavBar } from '../navBar/NavBar';
import { useState, useEffect } from 'react';

function countDistinctCountries(data) {
  if (!data) return 0;
  let countries = new Set();
  data.forEach((artwork) => {
    if (artwork["country-en"] !== "") {
      countries.add(artwork["country-en"]);
    }
  });
  return countries.size;
}

function countDistinctLanguages(data) {
  if (!data) return 0;
  let countries = new Set();
  data.forEach((artwork) => {
    if (artwork["language-en"] !== "") {
      countries.add(artwork["language-en"]);
    }
  });
  return countries.size;
}

export const WorldMap = () => {
  const selectedLanguage = localStorage.getItem('selectedLanguage') || 'es';
  const title = selectedLanguage === 'es' ? "¿De dónde vienen las postales?" : "Where do the postcards come from?";
  const [artworkData, setArtworkData] = useState(null);

  useEffect(() => {
    fetch("postales.json")
      .then((response) => response.json())
      .then((data) => {
        setArtworkData(data.artworks);
      });
  }, [artworkData]);

  // Definir las cadenas de texto que se van a mostrar dependiendo del idioma
  if (!artworkData) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="worldmap-parent">
        <NavBar active="mapa" />
        <h1>{title}</h1>
        <div>
          <img className='worldmap' src={'img/mapa.png'} alt="" />
          {selectedLanguage === 'es' ?
            <p>Actualmente, han participado <span className='highlight'>{countDistinctCountries(artworkData)} países</span>< br />
              y se ha escrito en <span className='highlight'>{countDistinctLanguages(artworkData)} idiomas.</span></p>
            : <p>So far, postcards have been written from<br />
              <span className='highlight'>{countDistinctCountries(artworkData)} countries</span> and in <span className='highlight'>{countDistinctLanguages(artworkData)} languages.</span> </p>}
        </div>

      </div>)
  }
}
